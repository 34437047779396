<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account-group">
          <template v-slot:heading>
            <h3 class="display-2">Sasaran Kinerja Pegawai Triwulan</h3>
          </template>

          <v-row>
            <v-col class="d-flex justify-end">
              <div v-if="tahun >= 2022"></div>
              <div v-else>
                <v-btn
                  color="yellow darken-3"
                  @click="syncSakip()"
                  :loading="sakipLoad"
                  dark
                  v-if="items.periode_sync_skp_triwulan"
                  ><v-icon left>mdi-sync</v-icon>
                  <v-img
                    max-width="100"
                    src="@/assets/logo_aplikasi_lain/ESAKIP.png"
                  ></v-img
                ></v-btn>
                <div>
                  <v-btn
                    color="green"
                    dark
                    class="mx-3"
                    @click="openDialogFormatSKP()"
                    v-if="
                      items.periode_input_skp ||
                      items.buka_tambah_skp_triwulan_by_nip ||
                      items.periode_input_jabatan_baru ||
                      items.is_kode_skpd_listed_tambah_triwulan
                    "
                    ><v-icon>mdi-plus</v-icon> Tambah</v-btn
                  >
                </div>
              </div>
              <!-- <v-btn color="green" dark class="mx-3" @click="create()" v-if="items.periode_input_skp || items.buka_tambah_skp_triwulan_by_nip || items.periode_input_jabatan_baru || items.buka_tambah_skp_tw_by_kode_skpd"><v-icon>mdi-plus</v-icon> Tambah</v-btn> -->
              <!-- <div v-if="getTriwulan()"> -->
            </v-col>
          </v-row>

          <!-- tab triwulan -->
          <v-row>
            <v-col>
              <v-btn-toggle v-model="tab" color="primary accent-3" mandatory>
                <v-btn value="1"> Triwulan 1 </v-btn>

                <v-btn value="2"> Triwulan 2 </v-btn>

                <v-btn value="3"> Triwulan 3 </v-btn>

                <v-btn value="4"> Triwulan 4 </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col align="right">
              <div v-if="tahun >= 2022">
                <!-- <v-tooltip top v-if="items.eselon == 2 && items.periode_sync_skp_triwulan"> -->
                <v-tooltip
                  top
                  v-if="
                    items.periode_sync_skp_triwulan ||
                    items.buka_sync_skp_triwulan_by_nip
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="cyan darken-4"
                      @click="syncSakip()"
                      :loading="sakipLoad"
                      class="mx-1"
                      dark
                      ><v-icon small left>mdi-sync</v-icon></v-btn
                    >
                  </template>
                  <span>Sinkronisasi SKP Triwulan Dengan E-Sakip</span>
                </v-tooltip>
                <!-- <v-btn color="cyan darken-3" @click="syncSakip()" :loading="sakipLoad" dark v-if="items.periode_sync_skp_triwulan"><v-icon left>mdi-sync</v-icon> <v-img max-width="100" src="@/assets/logo_aplikasi_lain/ESAKIP.png"></v-img></v-btn> -->
                <v-tooltip
                  top
                  v-if="
                    items.periode_input_skp ||
                    items.buka_tambah_skp_triwulan_by_nip ||
                    items.periode_input_jabatan_baru ||
                    items.buka_tambah_skp_tw_by_kode_skpd ||
                    walikota ||
                    items.is_kode_skpd_listed_tambah_triwulan
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="green"
                      @click="openDialogFormatSKP()"
                      dark
                      class="mx-1"
                      ><v-icon small left>mdi-plus</v-icon></v-btn
                    >
                  </template>
                  <span>Tambah SKP</span>
                </v-tooltip>
                <v-tooltip top v-if="walikota == false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="warning"
                      v-bind="attrs"
                      v-on="on"
                      @click="lihatFormEkspektasiPerilaku()"
                      dark
                      class="mx-1"
                      ><v-icon small left>mdi-account-check</v-icon></v-btn
                    >
                  </template>
                  <span>Lihat Ekspektasi Pimpinan</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
          <debug-btn :item="items"></debug-btn>
          <crud
            :crudSettings="crudSettings"
            :headers="walikota ? headers_ : headers"
            :responseData="items"
            :detailData="detailData"
            :forceUpdateTable="forceUpdate"
            :dataLoading="loading"
            :tabTriwulan="tab"
            @onTableChange="updateTable"
            @onEditClick="edit"
            @onDeleteClick="remove"
          >
            <template v-slot:append-actions="{ item }">
              <debug-btn :item="item"></debug-btn>

              <v-chip
                x-small
                v-if="item.validasi == 1"
                small
                class="success darken-2"
                dark
                ><v-icon small left>mdi-check-circle</v-icon>validasi</v-chip
              >

              <div v-if="enableLapor">
                <div v-if="item.skp_jabatan_saat_ini && getTriwulan()">
                  <div v-if="items.periode_tombol_lapor_skp_triwulan">
                    <v-btn
                      v-if="item.validasi == 4"
                      @click="lapor(item)"
                      color="success"
                      class="ma-1"
                      x-small
                    >
                      <v-icon x-small left>mdi-file</v-icon>
                      <span>Lapor Ulang</span>
                    </v-btn>
                    <v-btn
                      v-else-if="enableLapor && item.validasi != 1"
                      @click="lapor(item)"
                      color="success"
                      class="ma-1"
                      x-small
                    >
                      <v-icon x-small left>mdi-file</v-icon>
                      <span>Lapor</span>
                    </v-btn>
                  </div>
                </div>
                <div v-else-if="items.is_kode_skpd_listed_lapor_triwulan">
                  <v-btn
                    v-if="item.validasi != 1"
                    @click="lapor(item)"
                    color="success"
                    class="ma-1"
                    x-small
                  >
                    <v-icon x-small left>mdi-file</v-icon>
                    <span>{{
                      item.validasi == null ? "Lapor" : "Lapor Ulang"
                    }}</span>
                  </v-btn>
                </div>

                <!-- Handle Buka Lapor SKP TW 1/2/3/4 -->
                <div v-if="items.is_nip_listed_lapor_triwulan_1 && tab == 1">
                  <v-btn
                    v-if="item.validasi != 1"
                    @click="lapor(item)"
                    color="success"
                    class="ma-1"
                    x-small
                  >
                    <v-icon x-small left>mdi-file</v-icon>
                    <span>{{
                      item.validasi == null ? "Lapor" : "Lapor Ulang"
                    }}</span>
                  </v-btn>
                </div>
                <div v-if="items.is_nip_listed_lapor_triwulan_2 && tab == 2">
                  <v-btn
                    v-if="item.validasi != 1"
                    @click="lapor(item)"
                    color="success"
                    class="ma-1"
                    x-small
                  >
                    <v-icon x-small left>mdi-file</v-icon>
                    <span>{{
                      item.validasi == null ? "Lapor" : "Lapor Ulang"
                    }}</span>
                  </v-btn>
                </div>
                <div v-if="items.is_nip_listed_lapor_triwulan_3 && tab == 3">
                  <v-btn
                    v-if="item.validasi != 1"
                    @click="lapor(item)"
                    color="success"
                    class="ma-1"
                    x-small
                  >
                    <v-icon x-small left>mdi-file</v-icon>
                    <span>{{
                      item.validasi == null ? "Lapor" : "Lapor Ulang"
                    }}</span>
                  </v-btn>
                </div>
                <div v-if="items.is_nip_listed_lapor_triwulan_4 && tab == 4">
                  <v-btn
                    v-if="item.validasi != 1"
                    @click="lapor(item)"
                    color="success"
                    class="ma-1"
                    x-small
                  >
                    <v-icon x-small left>mdi-file</v-icon>
                    <span>{{
                      item.validasi == null ? "Lapor" : "Lapor Ulang"
                    }}</span>
                  </v-btn>
                </div>
              </div>
              <div v-else>
                <!-- Handle Buka Lapor SKP TW 1/2/3/4 -->
                <div v-if="items.is_nip_listed_lapor_triwulan_1 && tab == 1">
                  <v-btn
                    v-if="item.validasi != 1"
                    @click="lapor(item)"
                    color="success"
                    class="ma-1"
                    x-small
                  >
                    <v-icon x-small left>mdi-file</v-icon>
                    <span>{{
                      item.validasi == null ? "Lapor" : "Lapor Ulang"
                    }}</span>
                  </v-btn>
                </div>
                <div v-if="items.is_nip_listed_lapor_triwulan_2 && tab == 2">
                  <v-btn
                    v-if="item.validasi != 1"
                    @click="lapor(item)"
                    color="success"
                    class="ma-1"
                    x-small
                  >
                    <v-icon x-small left>mdi-file</v-icon>
                    <span>{{
                      item.validasi == null ? "Lapor" : "Lapor Ulang"
                    }}</span>
                  </v-btn>
                </div>
                <div v-if="items.is_nip_listed_lapor_triwulan_3 && tab == 3">
                  <v-btn
                    v-if="item.validasi != 1"
                    @click="lapor(item)"
                    color="success"
                    class="ma-1"
                    x-small
                  >
                    <v-icon x-small left>mdi-file</v-icon>
                    <span>{{
                      item.validasi == null ? "Lapor" : "Lapor Ulang"
                    }}</span>
                  </v-btn>
                </div>
                <div v-if="items.is_nip_listed_lapor_triwulan_4 && tab == 4">
                  <v-btn
                    v-if="item.validasi != 1"
                    @click="lapor(item)"
                    color="success"
                    class="ma-1"
                    x-small
                  >
                    <v-icon x-small left>mdi-file</v-icon>
                    <span>{{
                      item.validasi == null ? "Lapor" : "Lapor Ulang"
                    }}</span>
                  </v-btn>
                </div>
                <div v-if="tab >= triwulan_berjalan">
                  <span
                    v-if="item.validasi == null"
                    :class="
                      enableLapor
                        ? 'font-weight-medium my-1 success--text'
                        : 'font-weight-medium my-1 error--text'
                    "
                  >
                    <div
                      v-if="tab == triwulan_berjalan && cekLewatPeriodeLapor"
                    >
                      {{
                        "Periode Lapor SKP Triwulan " + tab + " Sudah Lewat "
                      }}
                    </div>
                    <div v-else>
                      {{
                        enableLapor
                          ? "Periode Lapor Dibuka"
                          : "Periode Lapor SKP Triwulan " +
                            tab +
                            " Belum Dimulai "
                      }}
                    </div>
                  </span>
                  <span
                    v-else-if="item.validasi == 1"
                    class="font-weight-medium my-1 success--text"
                    >SKP sudah divalidasi</span
                  >
                  <span
                    v-else
                    :class="
                      enableLapor
                        ? 'font-weight-medium my-1 success--text'
                        : 'font-weight-medium my-1 error--text'
                    "
                  >
                    {{
                      enableLapor
                        ? "Periode Lapor Dibuka"
                        : "Periode Lapor Sudah Lewat"
                    }}
                  </span>
                </div>
                <div v-else>
                  <span
                    v-if="item.validasi == 1"
                    class="font-weight-medium my-1 success--text"
                    >SKP sudah divalidasi</span
                  >
                  <span
                    v-else
                    :class="
                      enableLapor
                        ? 'font-weight-medium my-1 success--text'
                        : 'font-weight-medium my-1 error--text'
                    "
                  >
                    {{
                      enableLapor
                        ? "Periode Lapor Dibuka"
                        : "Periode Lapor Sudah Lewat"
                    }}
                  </span>
                </div>
              </div>

              <feedback-btn
                :item="item"
                :jenis="tab"
                @on-update-success="updateTable()"
              ></feedback-btn>
            </template>
          </crud>
        </base-material-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogLapor" max-width="600">
      <v-card>
        <v-card-text>
          <v-form ref="formLapor">
            <h1 class="display-2 my-2">Sasaran Kinerja Pegawai</h1>
            <div class="my-2">
              <span class="label font-weight-light">Jenis</span>
              <div class="font-weight-regular">{{ formLapor.jenis }}</div>
            </div>

            <div class="my-2">
              <span class="label font-weight-light">Sasaran Strategis</span>
              <div class="font-weight-regular">{{ formLapor.sasaran }}</div>
            </div>

            <v-row>
              <v-col>
                <div class="my-2">
                  <span class="label font-weight-light">Target</span>
                  <div class="font-weight-regular">
                    {{ formLapor.target }} {{ formLapor.satuan_target }}
                  </div>
                </div>
              </v-col>
              <v-col>
                <div class="my-2">
                  <span class="label font-weight-light">Nilai Pembalik</span>
                  <div class="font-weight-regular">{{ formLapor.np }}</div>
                </div>
              </v-col>
              <v-col>
                <div class="my-2">
                  <span class="label font-weight-light">Waktu</span>
                  <div class="font-weight-regular">{{ formLapor.wkt }}</div>
                </div>
              </v-col>
              <v-col>
                <div class="my-2">
                  <span class="label font-weight-light">Kualitas</span>
                  <div class="font-weight-regular">
                    {{ formLapor.kualitas }} %
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row
              v-if="
                !formLapor.is_sakip &&
                listDataSKPTriwulanSyncWithESakip.length > 0
              "
            >
              <v-col cols="8">
                <span class="label font-weight-bold"
                  >Pilih Indikator SKP Pada E-Sakip</span
                >
                <v-select
                  ref="getDataSKPTriwulanSyncWithESakip"
                  dense
                  v-model="formLapor.id_indikator_esakip"
                  :items="listDataSKPTriwulanSyncWithESakip"
                  item-text="text"
                  item-value="id_indikator_esakip"
                >
                  <!-- 
                outlined
                  :rules="
                    listDataSKPTriwulanSyncWithESakip.length > 0
                      ? [rules.required]
                      : []
                  "
                -->
                </v-select>
              </v-col>
              <v-col
                cols="4"
                v-if="text_target != '' && text_satuan_target != ''"
              >
                <div class="my-1">
                  <span class="label font-weight-bold"
                    >Target Pada E-Sakip</span
                  >
                  <div class="font-weight-bold">
                    {{ text_target }} {{ text_satuan_target }}
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col>
                <v-text-field
                  dense
                  v-model="formLapor.realisasi"
                  :rules="[rules.required]"
                  :suffix="formLapor.satuan_target"
                  outlined
                  label="Realisasi"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  dense
                  v-model="formLapor.capaian"
                  append-icon="mdi-percent"
                  disabled
                  outlined
                  label="Capaian"
                ></v-text-field>
              </v-col>
            </v-row>

            <file-input
              ref="fileInput"
              :errorInput="fileInputErrorFlag"
              :uploadUrl="uploadUrl"
              @fileUploaded="addFormFile"
              @delFileUploaded="delFormFile"
            ></file-input>

            <h1 class="display-2 my-2">Laporan Data Dan Pengukuran Kinerja</h1>
            <div class="my-2">
              <span class="label font-weight-light"
                >Formulasi Pengukuran, Alasan, dan Kriteria</span
              >
              <div class="font-weight-regular">
                {{ formLapor.formulasi || "-" }}
              </div>
            </div>

            <v-textarea
              v-model.lazy="formLapor.data_relevan"
              outlined
              label="Penjelasan Realisasi Dengan Target Kinerja Triwulan *"
              required
              hint="Wajib Diisi ! Minimal 25 karakter"
              :rules="[rules.required, rules.min25]"
            ></v-textarea>
            <!-- CATATAN TAMBAHAN by ISMAIL 10/06/2024 REQUEST BAPPELITBANG -->
            <v-textarea
              v-model.lazy="formLapor.hambatan"
              outlined
              label="Hambatan/Dukungan Dalam Memenuhi Target Kinerja Triwulan *"
              required
              hint="Wajib Diisi ! Minimal 25 karakter"
              :rules="[rules.required, rules.min25]"
            ></v-textarea>

            <v-textarea
              v-model.lazy="formLapor.pengukuran_kinerja"
              outlined
              label="Pengukuran Kinerja *"
              required
              hint="Wajib Diisi ! Minimal 25 karakter"
              :rules="[rules.required, rules.min25]"
            ></v-textarea>

            <h1 class="display-2 my-2">Indikator Keuangan</h1>
            <div class="my-2">
              <span class="label font-weight-light"
                >Formulasi Pengukuran, Alasan, dan Kriteria</span
              >
              <div class="font-weight-regular">
                {{ formLapor.nama_program }}
              </div>
            </div>
            <div class="my-2">
              <span class="label font-weight-light">Pagu</span>
              <div class="font-weight-regular">{{ formLapor.pagu }}</div>
            </div>
            <div class="my-2">
              <span class="label font-weight-light">Realisasi</span>
              <div class="font-weight-regular">
                {{ formLapor.realisasi_pagu }}
              </div>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="kirimLaporan()" class="success" dark>Laporkan</v-btn>
          <v-btn @click="dialogLapor = false">Tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogSKP" max-width="600px">
      <v-card>
        <v-card-text>
          <v-form ref="form">
            <h1 class="display-2 my-2">
              Entri
              {{
                dialogFormatSKPBaru == true
                  ? "Rencana Kinerja"
                  : "Sasaran Kinerja"
              }}
              Triwulan
            </h1>
            <template>
              <div v-if="dialogFormatSKPBaru">
                <v-select
                  dense
                  outlined
                  label="Rencana Kinerja Tahunan"
                  item-text="v"
                  item-value="k"
                  :items="skpTahunanEdit"
                  :rules="[rules.required]"
                  v-model="formEdit.skp_id"
                  required
                ></v-select>
                <v-text-field
                  dense
                  outlined
                  label="Rencana Kinerja Triwulan"
                  :rules="[rules.required]"
                  v-model="formEdit.rencana_kinerja"
                  required
                ></v-text-field>
                <v-text-field
                  dense
                  outlined
                  label="Indikator Kinerja Triwulan"
                  :rules="[rules.required]"
                  v-model="formEdit.indikator_bulanan"
                  required
                ></v-text-field>

                <!-- Penambahan perspektif Permenpan 6 untuk JPT -->
                <v-select
                  v-if="items.eselon == 2 || isPLT"
                  dense
                  v-model="formEdit.perspektif"
                  :items="listPerspektif"
                  :rules="[rules.required]"
                  item-text="text"
                  item-value="value"
                  label="Perspektif"
                  outlined
                ></v-select>
                <v-select
                  v-else
                  disabled
                  dense
                  v-model="formEdit.perspektif"
                  :items="listPerspektif"
                  item-text="text"
                  item-value="value"
                  label="Perspektif"
                  outlined
                ></v-select>

                <h1 class="display-2 my-2">Indikator Kinerja</h1>
                <v-select
                  dense
                  v-model="formEdit.aspek"
                  :items="listAspek2"
                  item-text="text"
                  item-value="value"
                  label="Aspek"
                  outlined
                ></v-select>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      dense
                      outlined
                      :rules="[rules.required]"
                      label="Target"
                      v-model="formEdit.target"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="8">
                    <v-select
                      dense
                      outlined
                      label="Satuan"
                      :rules="[rules.required]"
                      :items="satuanSKP"
                      v-model="formEdit.satuan_target"
                      required
                    ></v-select>
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col>
                    <v-text-field dense v-model="formEdit.target_bawah" :rules="[rules.required, rules.onlyInt]" outlined label="Target Batas Bawah"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field dense v-model="formEdit.target_atas" outlined label="Target Batas Atas"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-select dense v-model="formEdit.satuan_target" :rules="[rules.required]" outlined :items="satuanSKP" label="Satuan"></v-select>
                  </v-col>
                </v-row> -->
                <!-- <v-text-field dense label="Kualitas" outlined :rules="[rules.required, rules.onlyInt]" v-model="formEdit.kualitas" append-icon="mdi-percent"></v-text-field> -->
                <!-- <v-row align="center">
                  <v-col>
                    Waktu
                  </v-col>
                  <v-col cols="3">
                    <v-select dense outlined :items='nilaiBulan' :rules="[rules.required]" v-model="formEdit.waktu"></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select dense outlined :items='["bulan"]' :rules="[rules.required]" v-model="formEdit.satuan_waktu"></v-select>
                  </v-col>
                </v-row> -->
                <v-row align="center">
                  <span class="mx-3" style="font-size: 12px"
                    >Rumus Penurunan</span
                  >
                  <v-checkbox
                    label="Nilai Pembalik"
                    v-model="formEdit.is_pembalik"
                  ></v-checkbox>
                </v-row>
                <v-row align="center">
                  <span class="mx-3">Direct Cascading</span>
                  <v-checkbox
                    class="mx-3"
                    style="margin-top: 0px !important"
                    v-model="formEdit.direct_cascading"
                    label="Centang pilihan direct cascading untuk rencana kinerja yang dapat dibagi berdasarkan aspek, wilayah, dan/atau beban target kuantitatif "
                  ></v-checkbox>
                </v-row>
              </div>
              <div v-else>
                <v-select
                  dense
                  outlined
                  label="SKP Tahunan"
                  item-text="v"
                  item-value="k"
                  :items="skpTahunanEdit"
                  :rules="[rules.required]"
                  v-model="formEdit.skp_id"
                  required
                ></v-select>
                <v-text-field
                  dense
                  outlined
                  label="SKP Triwulan"
                  :rules="[rules.required]"
                  v-model="formEdit.sasaran"
                  required
                ></v-text-field>

                <h1 class="display-2 my-2">Indikator Kinerja</h1>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      dense
                      outlined
                      :rules="[rules.required, rules.onlyInt]"
                      label="Target"
                      v-model="formEdit.target"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="8">
                    <v-select
                      dense
                      outlined
                      label="Satuan"
                      :rules="[rules.required]"
                      :items="satuanSKP"
                      v-model="formEdit.satuan_target"
                      required
                    ></v-select>
                  </v-col>
                </v-row>
                <v-text-field
                  dense
                  label="Kualitas"
                  outlined
                  :rules="[rules.required, rules.onlyInt]"
                  v-model="formEdit.kualitas"
                  append-icon="mdi-percent"
                ></v-text-field>
                <v-row align="center">
                  <v-col> Waktu </v-col>
                  <v-col cols="3">
                    <v-select
                      dense
                      outlined
                      :items="nilaiBulan"
                      :rules="[rules.required]"
                      v-model="formEdit.waktu"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      dense
                      outlined
                      :items="['bulan']"
                      :rules="[rules.required]"
                      v-model="formEdit.satuan_waktu"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <span class="mx-3" style="font-size: 12px"
                    >Rumus Penurunan</span
                  >
                  <v-checkbox
                    label="Nilai Pembalik"
                    v-model="formEdit.is_pembalik"
                  ></v-checkbox>
                </v-row>
              </div>
            </template>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            @click="save()"
            :loading="loadingBtn.dialogSKP"
            dark
            >Simpan</v-btn
          >
          <v-btn @click="dialogSKP = false">Tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogFormatSKP" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">Pilih Format SKP</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formFormatSKP">
            <v-row align="center">
              <v-col cols="4" align="center">
                <v-btn color="info" @click="createItem('lama')"
                  >PP 46/2011</v-btn
                >
              </v-col>
              <v-col cols="4" align="center">
                <v-btn color="success" @click="createItem('baru')"
                  >Permenpan 8/2021</v-btn
                >
              </v-col>
              <v-col cols="4" align="center" v-if="tahun >= 2022">
                <v-btn
                  color="cyan darken-4"
                  dark
                  @click="createItem('per6/2022')"
                  >Permenpan 6/2022</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="tahun >= 2022"
      v-model="dialogFormEkspektasiPerilaku"
      :persistent="dialogFormEkspektasiPerilakuPersistent"
    >
      <FormEkspektasiPerilaku
        :aria-readonly="readOnlyFormEkspektasi"
        :triwulan="tab"
        :dataPegawaiEkspektasiPerilaku="dataPegawaiEkspektasiPerilaku"
        @onClose="
          (x) => {
            dialogFormEkspektasiPerilaku = x;
          }
        "
        @onTableChange="updateTable"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "lodash";
import store from "@/store";
import Crud from "@/components/common/Crud";
import SKPService from "@/services/SKPService";
import FileInput from "@/components/common/FileInput";
import FeedbackBtn from "./components/FeedbackBtn";
import DebugBtn from "@/components/common/DebugBtn";
import _g from "@/global";
import FormEkspektasiPerilaku from "./FormEkspektasiPerilaku.vue";
import axios from "axios";

export default {
  components: {
    Crud,
    FileInput,
    FeedbackBtn,
    DebugBtn,
    FormEkspektasiPerilaku,
  },

  data() {
    return {
      forceUpdate: false,
      loading: false,
      sakipLoad: false,
      tab: "1",
      dialogSKP: false,
      dialogLapor: false,
      dialogFormatSKP: false,
      dialogFormatSKPBaru: false,
      enableAll: false,
      formEdit: {},
      formLapor: { file: [] },
      fileInputErrorFlag: false,
      skpTahunanEdit: [],
      loadingBtn: {},
      satuanSKP: [],
      rules: {
        required: (v) => !!v || "Tidak boleh kosong",
        onlyInt: (v) => /^\d+$/.test(v) || "Hanya boleh angka",
        min25: (v) => (v && v.length > 25) || "Minimal 25 karakter",
      },

      headers: [
        {
          text: "No",
          value: "nomer",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Rencana Kinerja Tahunan",
          value: "sasaran",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Rencana Kinerja Triwulan",
          value: "rencana_kinerja",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Indikator Kinerja Triwulan",
          value: "indikator_bulanan",
          sortable: false,
          class: "header-index-profile",
        },
        // {text:'Sasaran/Rencana Kinerja Tahunan', value:'sasaran_kinerja_tahunan', sortable:false, class:'header-index-profile'},
        // {text:'Sasaran/Rencana Kinerja Triwulan', value:'sasaran', sortable:false, class:'header-index-profile'},
        // {text:'Indikator Kinerja', value:'indikator_bulanan', sortable:false, class:'header-index-profile'},
        {
          text: "Target Output",
          value: "target_triwulan",
          sortable: false,
          class: "header-index-profile",
        },
        // {text:'Waktu', value:'waktu', sortable:false, class:'header-index-profile'},
        {
          text: "Status SKP",
          value: "teks_status",
          sortable: false,
          class: "header-index-profile",
        },
        { value: "aksi", sortable: false },
      ],
      headers_: [
        {
          text: "No",
          value: "nomer",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Sasaran Strategis Tahunan",
          value: "sasaran",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Sasaran Strategis Triwulan",
          value: "rencana_kinerja",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Indikator Sasaran Strategis Triwulan",
          value: "indikator_bulanan",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Target Output",
          value: "target_triwulan",
          sortable: false,
          class: "header-index-profile",
        },
        // {text:'Waktu', value:'waktu', sortable:false, class:'header-index-profile'},
        {
          text: "Status",
          value: "teks_status",
          sortable: false,
          class: "header-index-profile",
        },
        { value: "aksi", sortable: false },
      ],
      items: {},

      responseData: {},
      tableValues: { itemsPerPage: 10, page: 1 },
      dialogReset: false,
      detailData: {},
      detailPegawai: {},
      itemUser: {},
      listAspek2: ["Kuantitas", "Kualitas", "Waktu"],
      formatSKP: "",
      tab_triwulan: null,
      triwulan_berjalan: null,
      listPerspektif: [
        {
          value: "Perspektif penerima layanan",
          text: "Perspektif penerima layanan",
        },
        { value: "Perspektif proses bisnis", text: "Perspektif proses bisnis" },
        {
          value: "Perspektif penguatan internal",
          text: "Perspektif penguatan internal",
        },
        { value: "Perspektif anggaran", text: "Perspektif anggaran" },
      ],
      tahun: null,
      walikota: false,
      dataPegawaiEkspektasiPerilaku: [],
      dialogFormEkspektasiPerilaku: false,
      dialogFormEkspektasiPerilakuPersistent: false,
      readOnlyFormEkspektasi: false,
      listDataSKPTriwulanSyncWithESakip: [],
      listItemIndikator: [],
      text_target: "",
      text_satuan_target: "",
    };
  },

  beforeCreate() {
    // SKPService.getSKPTriwulan({
    //   search:this.tableValues.search ? this.tableValues.search: '',
    //   triwulan:this.tab,
    //   page:this.tableValues.page})
    // .then((response)=>{
    //   let data = []
    //   this.responseData = response.data
    //   let responseData = response.data
    //   for (let i = 0; i < responseData.data.length; i++){
    //     data.push(
    //       responseData.data[i]);
    //     data[i].waktu = data[i].waktu + " "+data[i].satuan_waktu
    //   }
    //   responseData.data = data
    //   this.items = responseData
    //   this.triwulan_berjalan = parseInt(responseData.triwulan_berjalan)
    //   this.loading= false
    // })
  },

  watch: {
    "formEdit.skp_id": function (val) {
      console.log(val);
    },

    "formLapor.id_indikator_esakip": function (val) {
      for (let i = 0; i < this.listItemIndikator.length; i++) {
        const el = this.listItemIndikator[i];
        if (val == el.id) {
          console.log("masuk");
          if (this.tab == 1) {
            this.text_target = el.target_tw1;
          }
          if (this.tab == 2) {
            this.text_target = el.target_tw2;
          }
          if (this.tab == 3) {
            this.text_target = el.target_tw3;
          }
          if (this.tab == 4) {
            this.text_target = el.target_tw4;
          }
          this.text_satuan_target = el.satuan;
        }
      }
    },

    tab: function () {
      this.forceUpdate = true;
      this.updateTable();
    },

    forceUpdate: function () {
      console.log("callign forceupdate skp triwulan");
    },

    dialogSKP: function (val) {
      console.log(val);
      if (val) {
        setTimeout(() => {
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
        }, 500);
      }
    },

    dialogLapor: function (val) {
      console.log(val);
      if (val) {
        setTimeout(() => {
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
        }, 500);
      }
    },
  },

  created() {
    this.tahun = this.$store.getters["user/data"].tahun;
    let username = this.$store.getters["user/data"].username;
    if (username == "walikotabdg") {
      this.walikota = true;
    } else {
      this.walikota = false;
    }
    // this.updateTable(this.tableValues)
    SKPService.satuanSKP().then((response) => {
      this.satuanSKP = response.data.data.sort((a, b) => {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
    });

    SKPService.getDropdownListSKPTahunan().then((response) => {
      let items = response.data.data;
      let results = [];
      _.forOwn(items, (val, key) => {
        results.push({ k: key, v: val });
      });
      console.log(response.data.data);
      this.skpTahunanEdit = _.clone(results);
    });

    // get ekspektasi perilaku
    var current = store.state.user.data;
    var tahun = this.$store.getters["user/data"].tahun;
    let user = store.state.user;
    if (
      current.current_role.includes("PLT") ||
      current.current_role.includes("Kepala UPT Puskesmas")
    ) {
      current.username = _g.overrideWithPLT(current.current_role);
    }

    var base_url_api = _g.getBaseURLAPIERK(tahun);
    var url_get_data = base_url_api + "v1/get-ekspektasi-perilaku";
    var triwulan_ = this.tab;
    this.dataPegawaiEkspektasiPerilaku = [];
    axios
      .post(url_get_data, {
        nip_atasan: null,
        nip: current.username,
        triwulan: triwulan_,
        tahun: tahun,
      })
      .then((response) => {
        if (this.tahun >= 2022) {
          let res = response.data.data;
          if (this.triwulan != "tahun") {
            if (res != null) {
              this.dataPegawaiEkspektasiPerilaku = res;
            }
          }
          this.dataPegawaiEkspektasiPerilaku.foto = user.data.data_asn.foto;
          this.dataPegawaiEkspektasiPerilaku.nip = user.data.data_asn.nip;
          this.dataPegawaiEkspektasiPerilaku.jabatan =
            user.data.data_asn.jabatan;
          this.dataPegawaiEkspektasiPerilaku.nama = user.data.data_asn.nama;
        }
      })
      .finally(() => {
        this.readOnlyFormEkspektasi = true;
      })
      .catch((err) => {
        var error = err.message ? err.message : err.response.data.message;
        this.loadingEl = -1;
        store.commit("snackbar/setSnack", { message: error, color: "error" });
      });
  },

  computed: {
    cekLewatPeriodeLapor() {
      return this.responseData.periode_lapor_skp_triwulan_terlewat;
    },
    enableLapor() {
      return (
        this.responseData.periode_tombol_lapor_skp_triwulan ||
        this.responseData.is_nip_listed_lapor_triwulan ||
        this.enableAll ||
        this.responseData.is_kode_skpd_listed_lapor_triwulan
      );
    },

    uploadUrl() {
      // return process.env.VUE_APP_API_URL + '/api/skp-triwulan-upload-evidence'
      return (
        process.env.VUE_APP_API_URL + "/api/ms/1/upload-evidence-skp-triwulan"
      );
    },

    crudSettings() {
      return {
        title: "",
        detailTitle: "Detail",
        deleteTitleKey: "sasaran",
        enableEdit:
          this.responseData.is_nip_listed_edit_tahunan ||
          this.responseData.is_nip_listed_lapor_triwulan ||
          this.enableAll ||
          this.responseData.periode_input_skp ||
          this.responseData.buka_tambah_skp_triwulan_by_nip ||
          this.responseData.periode_input_jabatan_baru ||
          this.responseData.is_kode_skpd_listed_edit_triwulan, //this.responseData.periode_tombol_lapor_skp_triwulan
        enableDelete:
          this.responseData.is_nip_listed_hapus_tahunan ||
          this.responseData.is_nip_listed_lapor_triwulan ||
          this.enableAll ||
          this.responseData.periode_input_skp ||
          this.responseData.periode_input_jabatan_baru ||
          this.responseData.is_kode_skpd_listed_hapus_triwulan,
        enableDetail: false,
        enableCreate: false,
      };
    },

    nilaiBulan() {
      return _.range(1, 13);
    },

    capaian() {
      if (this.formLapor.is_pembalik) {
        return (
          ((this.formLapor.target * 1) / this.formLapor.realisasi) * 1 * 100
        );
      }
      return ((this.formLapor.realisasi * 1) / this.formLapor.target) * 1 * 100;
    },

    isPLT() {
      return _g.isPLT();
    },
  },

  methods: {
    getTriwulan() {
      var triwulan = _g.getTriwulan();
      var tab = parseInt(this.tab);
      this.tab_triwulan = tab;
      if (tab == triwulan) return true;
      else return false;
    },
    addFormFile(file) {
      this.formLapor.file.push(file);
      // this.formLapor.file.push(file.name)
      console.log(this.formLapor.file);
    },

    delFormFile(file) {
      let regex = /\d+_([\w\s-]+)-[\w\d]+\.(\w+)/;
      let filename = file.file.name;
      this.formLapor.file = this.formLapor.file.filter((val) => {
        const match = val.match(regex);
        let curr_filename = match[1] + "." + match[2];
        if (match) {
          return filename != curr_filename;
        }
        return false;
      });
    },

    create() {
      this.dialogSKP = true;
      this.formEdit = {};
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },

    openDialogFormatSKP() {
      this.dialogFormatSKP = true;
      setTimeout(() => {
        document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
      }, 500);
    },

    createItem(e) {
      if (e == "per6/2022") {
        this.dialogFormatSKPBaru = true;
      } else if (e == "baru") {
        this.dialogFormatSKPBaru = true;
      } else {
        this.dialogFormatSKPBaru = false;
      }
      this.formatSKP = e;
      this.formEdit = {};
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      var params = {};
      params.format_skp = this.formatSKP;
      SKPService.getDropdownListSKPTahunan(params).then((response) => {
        let items = response.data.data;
        let results = [];
        _.forOwn(items, (val, key) => {
          results.push({ k: key, v: val });
        });
        console.log(response.data.data);
        this.skpTahunanEdit = _.clone(results);
        this.dialogSKP = true;
      });
      // this.$refs.form.resetValidation()
      setTimeout(() => {
        document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
      }, 500);
    },

    save() {
      if (this.$refs.form.validate()) {
        console.log(this.tab, this.formEdit);
        this.loadingBtn.dialogSKP = true;
        this.formEdit.format_skp = this.formatSKP;
        this.formEdit.target =
          this.formEdit.target != null && this.formEdit.target != ""
            ? this.formEdit.target
            : null;
        this.formEdit.kualitas =
          this.formatSKP == "baru" ? 100 : this.formEdit.kualitas;
        for (let i = 0; i < this.skpTahunanEdit.length; i++) {
          const element = this.skpTahunanEdit[i];
          if (element.k == this.formEdit.skp_id) {
            this.formEdit.sasaran = element.v;
            break;
          }
        }
        // this.formEdit.target = 0
        SKPService.storeTriwulan(this.tab, this.formEdit, this.formEdit.id)
          .then((response) => {
            let res = response.data;
            if (res.success) {
              store.commit("snackbar/setSnack", {
                message: res.message,
                color: "success",
              });
              this.updateTable();
              this.dialogFormatSKP = false;
            } else {
              store.commit("snackbar/setSnack", {
                message: res.message,
                color: "error",
              });
            }
          })
          .finally(() => {
            this.loadingBtn.dialogSKP = false;
            this.dialogSKP = false;
          });
      }
    },

    lapor(item) {
      console.log(item);
      this.dialogLapor = true;
      this.formLapor = {};

      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      // defer assign data
      this.$nextTick(() => {
        this.fileInputErrorFlag = false;
        this.formLapor = _.clone(item);

        if (this.$refs.fileInput) {
          this.$refs.fileInput.clearAll();
        }

        this.formLapor.file = [];
        this.formLapor.jenis = "Triwulan " + item.bulan;
        this.formLapor.np = item.is_pembalik ? "Ya" : "Tidak";
        this.formLapor.wkt = item.waktu.replace("null", "-");
        this.formLapor.kualitas = item.kualitas ? item.kualitas : "0 %";
      });
    },

    kirimLaporan() {
      let flag = false;
      if (
        this.formLapor.file === undefined ||
        this.formLapor.file.length == 0
      ) {
        this.fileInputErrorFlag = true;
      } else {
        flag = true;
      }
      if (this.responseData.is_struktural == false) {
        flag = true;
        this.fileInputErrorFlag = false;
      }
      this.formLapor.id_skp =
        this.formLapor.skp_id == null ? "-" : this.formLapor.skp_id;
      this.formLapor.skp_id =
        this.formLapor.skp_id == null ? "-" : this.formLapor.skp_id;
      if (this.$refs.formLapor && this.$refs.formLapor.validate() && flag) {
        this.listDataSKPTriwulanSyncWithESakip.forEach((el) => {
          if (el.id_indikator_esakip == this.formLapor.id_indikator_esakip) {
            this.formLapor.level = el.level;
          }
        });
        // SKPService.laporTriwulan(this.formLapor).then(response=>{
        //   let res = response.data
        //   if (res.success){
        //     store.commit('snackbar/setSnack',{message: res.message, color:'success'})
        //     this.updateTable()
        //     this.dialogLapor=false
        //   }else {
        //     store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        //   }
        // });
        var url_get_data =
          _g.getBaseURLAPIERK(this.$store.getters["user/data"].tahun) +
          "v1/lapor-skp-triwulan";
        axios
          .post(url_get_data, this.formLapor)
          .then((response) => {
            let res = response.data;
            if (res.success) {
              store.commit("snackbar/setSnack", {
                message: res.message,
                color: "success",
              });
            } else {
              store.commit("snackbar/setSnack", {
                message: res.message,
                color: "error",
              });
            }
          })
          .finally(() => {
            this.updateTable();
            this.dialogLapor = false;
          })
          .catch((err) => {
            var error = err.message ? err.message : err.response.data.message;
            store.commit("snackbar/setSnack", {
              message: error,
              color: "error",
            });
          });
      }
    },

    edit(item) {
      console.log(item);
      if (
        (item.rencana_kinerja != null && item.rencana_kinerja != "") ||
        (item.rencana_kinerja_tahunan != null &&
          item.rencana_kinerja_tahunan != "")
      ) {
        this.dialogFormatSKPBaru = true;
        this.formEdit.format_skp = "baru";
        this.formatSKP = "baru";
      } else {
        this.dialogFormatSKPBaru = false;
        this.formEdit.format_skp = "lama";
        this.formatSKP = "lama";
      }
      var params = {};
      params.format_skp = this.formatSKP;
      SKPService.getDropdownListSKPTahunan(params).then((response) => {
        let items = response.data.data;
        let results = [];
        _.forOwn(items, (val, key) => {
          results.push({ k: key, v: val });
        });
        console.log(response.data.data);
        this.skpTahunanEdit = _.clone(results);
        // this.dialogSKP=true
      });
      this.dialogSKP = true;

      this.formEdit = _.clone(item);
      this.formEdit.skp_id = this.formEdit.skp_id + "";

      try {
        this.formEdit.waktu = this.formEdit.waktu.match(/\d+/)[0] * 1;
      } catch (err) {
        this.formEdit.waktu = null;
      }
    },

    remove(item) {
      SKPService.hapusSKPTriwulan(item.id, this.tab).then((response) => {
        console.log(response.data);
        let res = response.data;
        if (res.success) {
          store.commit("snackbar/setSnack", {
            message: res.message,
            color: "success",
          });
          this.updateTable();
        } else {
          store.commit("snackbar/setSnack", {
            message: res.message,
            color: "error",
          });
        }
      });
    },

    getImg(nip, ext) {
      if (nip) {
        let avatarImgUrl = process.env.VUE_APP_IMG_URL + nip + "." + ext;
        // this.avatarImages[nip] = avatarImgUrl
        return avatarImgUrl;
      }
      return "";
    },

    laporUlang() {},

    syncSakip() {
      this.sakipLoad = true;
      SKPService.syncSakipTriwulan(this.tab)
        .then((response) => {
          let res = response.data;
          if (res.success) {
            store.commit("snackbar/setSnack", {
              message: res.message,
              color: "success",
            });
            this.updateTable();
          } else {
            store.commit("snackbar/setSnack", {
              message: res.message,
              color: "error",
            });
          }
        })
        .finally(() => {
          this.sakipLoad = false;
        });
    },

    updateTable(val) {
      this.forceUpdate = false;
      this.tableValues = val ? val : this.tableValues;

      if (this.tableValues) {
        this.loading = true;
        this.items = {};
        SKPService.getSKPTriwulan({
          search: this.tableValues.search ? this.tableValues.search : "",
          triwulan: this.tab,
          page: this.tableValues.page,
        }).then((response) => {
          let data = [];
          this.responseData = response.data;
          let responseData = response.data;
          for (let i = 0; i < responseData.data.length; i++) {
            data.push(responseData.data[i]);
            data[i].waktu = data[i].waktu + " " + data[i].satuan_waktu;
          }
          responseData.data = data;
          this.items = responseData;
          this.triwulan_berjalan = parseInt(responseData.triwulan_berjalan);
          this.loading = false;

          let user = store.state.user;
          if (!user.current.role.includes("pegawai")) {
            this.getDataSKPTriwulanSyncWithESakip_();
          }
        });
      }

      // var current = store.state.user.data
      // var tahun = this.$store.getters["user/data"].tahun
      // SKPService.getEkspektasiPerilakuKerja(current.username, 'all', tahun).then(response=>{
      //   let res =response.data
      //   if(this.tahun >= 2022){
      //     var triwulan = this.tab
      //     var arr = []
      //     res.data.forEach(el => {
      //       if(triwulan == el.triwulan){
      //         arr.push(el)
      //       }
      //     });
      //     res.data = arr
      //     let user = store.state.user
      //     this.dataPegawaiEkspektasiPerilaku = []
      //     this.dataPegawaiEkspektasiPerilaku = res.data
      //     this.dataPegawaiEkspektasiPerilaku.foto = user.data.data_asn.foto
      //     this.dataPegawaiEkspektasiPerilaku.nip = user.data.data_asn.nip
      //     this.dataPegawaiEkspektasiPerilaku.jabatan = user.data.data_asn.jabatan
      //     this.dataPegawaiEkspektasiPerilaku.nama = user.data.data_asn.nama
      //   }
      // }).finally(()=>{
      //   this.readOnlyFormEkspektasi = true
      // })
      // let current = store.state.user.current
      // let user = store.state.user
      // if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas")){
      //   current.username = _g.overrideWithPLT(user.current.role)
      // }else{
      //   current.username = user.current.username
      // }

      // var base_url_api = _g.getBaseURLAPIERK(current.year)
      // const url_get_data = base_url_api + 'v1/get-ekspektasi-perilaku'
      // axios.post(url_get_data, {
      //   nip_atasan: null, nip: current.username, triwulan: 'all', tahun: current.year
      // }).then(response => {
      //   let res =response.data
      //   if(this.tahun >= 2022){
      //     var triwulan = this.tab
      //     var arr = []
      //     res.data.forEach(el => {
      //       if(triwulan == el.triwulan){
      //         arr.push(el)
      //       }
      //     });
      //     res.data = arr
      //     let user = store.state.user
      //     this.dataPegawaiEkspektasiPerilaku = []
      //     this.dataPegawaiEkspektasiPerilaku = res.data
      //     this.dataPegawaiEkspektasiPerilaku.foto = user.data.data_asn.foto
      //     this.dataPegawaiEkspektasiPerilaku.nip = user.data.data_asn.nip
      //     this.dataPegawaiEkspektasiPerilaku.jabatan = user.data.data_asn.jabatan
      //     this.dataPegawaiEkspektasiPerilaku.nama = user.data.data_asn.nama
      //   }
      // }).finally(()=>{
      //   this.readOnlyFormEkspektasi = true
      // }).catch(err => {
      //   var error = err.message ? err.message : err.response.data.message
      //   this.loadingEl = -1
      //   store.commit('snackbar/setSnack',{message: error, color:'error'})
      // })
    },

    lihatFormEkspektasiPerilaku() {
      this.readOnlyFormEkspektasi = true;
      this.dialogFormEkspektasiPerilaku = true;
    },

    getDataSKPTriwulanSyncWithESakip_() {
      // this.loadingBtn.syncSakipProgram = true
      let current = store.state.user.current;
      let user = store.state.user;
      var base_url_api = _g.getBaseURLAPIERK(current.year);
      const url_get_data = base_url_api + "v1/get-sync-skp-sakip-triwulan";
      if (
        user.current.role.includes("PLT") ||
        user.current.role.includes("Kepala UPT Puskesmas") ||
        user.current.role.includes("PLH")
      ) {
        current.username = _g.overrideWithPLT(user.current.role);
      } else {
        current.username = user.current.username;
      }
      // this.tempListSasaranStrategis = this.listSasaranStrategis
      // this.listSasaranStrategis = []
      this.listDataSKPTriwulanSyncWithESakip = [];
      this.formEdit.target_bawah = "";
      axios
        .post(url_get_data, {
          api: 1,
          nip: current.username,
        })
        .then((response) => {
          console.log("tes:", response);
          if (response.data.success) {
            // let res =response.data.data.data
            let res = response.data.data;
            res.forEach((el) => {
              this.listItemIndikator.push(el);
            });
            res.forEach((el) => {
              this.listDataSKPTriwulanSyncWithESakip.push({
                text: el.text,
                id_indikator_esakip: el.id,
                level: el.level,
              });
            });
            console.log(
              "listDataSKPTW",
              this.listDataSKPTriwulanSyncWithESakip
            );
            for (
              let i = 0;
              i < this.listDataSKPTriwulanSyncWithESakip.length;
              i++
            ) {
              const element = this.listDataSKPTriwulanSyncWithESakip[i];
              var cek_id_indikator_esakip = element.id_indikator_esakip;
              for (let j = 0; j < this.responseData.data.length; j++) {
                const skp = this.responseData.data[j];
                if (skp.level == "sasaran") {
                  if (skp.id_indikator == cek_id_indikator_esakip) {
                    this.listDataSKPTriwulanSyncWithESakip.splice(i, 1);
                  }
                }
                if (skp.level == "program") {
                  if (skp.i_idindikator_program == cek_id_indikator_esakip) {
                    this.listDataSKPTriwulanSyncWithESakip.splice(i, 1);
                  }
                }
                if (skp.level == "kegiatan") {
                  if (skp.i_idindikator_kegiatan == cek_id_indikator_esakip) {
                    this.listDataSKPTriwulanSyncWithESakip.splice(i, 1);
                  }
                }
                if (skp.level == "subkegiatan") {
                  console.log(
                    "masuk",
                    skp.i_idindikator_subkegiatan,
                    cek_id_indikator_esakip
                  );
                  if (
                    skp.i_idindikator_subkegiatan == cek_id_indikator_esakip
                  ) {
                    this.listDataSKPTriwulanSyncWithESakip.splice(i, 1);
                  }
                }
              }
            }
            // this.listDataSKPTriwulanSyncWithESakip.forEach(function(item, index, object) {
            //   var cek_id_indikator_esakip = item.id
            //   console.log('tesResponseData', this.responseData)
            //   this.responseData.data.forEach(skp => {
            //     if(skp.level == "sasaran"){
            //       if(skp.id_indikator == cek_id_indikator_esakip){
            //         object.splice(index, 1)
            //       }
            //     }
            //     if(skp.level == "program"){
            //       if(skp.i_idindikator_program == cek_id_indikator_esakip){
            //         object.splice(index, 1)
            //       }
            //     }
            //     if(skp.level == "kegiatan"){
            //       if(skp.i_idindikator_kegiatan == cek_id_indikator_esakip){
            //         object.splice(index, 1)
            //       }
            //     }
            //     if(skp.level == "subkegiatan"){
            //       if(skp.i_idindikator_subkegiatan == cek_id_indikator_esakip){
            //         object.splice(index, 1)
            //       }
            //     }
            //   });
            // });
            // res.forEach(listData => {
            //     var cek_id_indikator_esakip = listData.id
            //     this.responseData.data.forEach(skp => {
            //       if(skp.level == "sasaran"){
            //         if(cek_id_indikator_esakip != skp.id_indikator){
            //           this.listDataSKPTriwulanSyncWithESakip.push({
            //             "text":listData.text,
            //             "id_indikator_esakip":listData.id,
            //             "level":listData.level //level di sini berisi sasaran, program/kegiatan, atau subkegiatan
            //           })
            //         }
            //       }
            //       if(skp.level == "program"){
            //         if(cek_id_indikator_esakip != skp.i_idindikator_program){
            //           this.listDataSKPTriwulanSyncWithESakip.push({
            //             "text":listData.text,
            //             "id_indikator_esakip":listData.id,
            //             "level":listData.level //level di sini berisi sasaran, program/kegiatan, atau subkegiatan
            //           })
            //         }
            //       }
            //       if(skp.level == "kegiatan"){
            //         if(cek_id_indikator_esakip != skp.i_idindikator_kegiatan){
            //           this.listDataSKPTriwulanSyncWithESakip.push({
            //             "text":listData.text,
            //             "id_indikator_esakip":listData.id,
            //             "level":listData.level //level di sini berisi sasaran, program/kegiatan, atau subkegiatan
            //           })
            //         }
            //       }
            //       if(skp.level == "subkegiatan"){
            //         if((cek_id_indikator_esakip != skp.i_idindikator_subkegiatan) && (skp.i_idindikator_subkegiatan != null)){
            //           this.listDataSKPTriwulanSyncWithESakip.push({
            //             "text":listData.text,
            //             "id_indikator_esakip":listData.id,
            //             "level":listData.level //level di sini berisi sasaran, program/kegiatan, atau subkegiatan
            //           })
            //         }
            //       }
            //     });

            // });
            // console.log('res: ', res)
            // res.forEach(el => {
            //   console.log('el: ', el)
            //   if(this.tab == 1){
            //     if(el.realisasi_tw1 == '-'){
            //       this.listDataSKPTriwulanSyncWithESakip.push({
            //         "text":el.text,
            //         "id_indikator_esakip":el.id,
            //         "level":el.level //level di sini berisi sasaran, program/kegiatan, atau subkegiatan
            //       })
            //     }
            //   }
            //   if(this.tab == 2){
            //     if(el.realisasi_tw2 == '-'){
            //       this.listDataSKPTriwulanSyncWithESakip.push({
            //         "text":el.text,
            //         "id_indikator_esakip":el.id,
            //         "level":el.level
            //       })
            //     }
            //   }
            //   if(this.tab == 3){
            //     if(el.realisasi_tw3 == '-'){
            //       this.listDataSKPTriwulanSyncWithESakip.push({
            //         "text":el.text,
            //         "id_indikator_esakip":el.id,
            //         "level":el.level
            //       })
            //     }
            //   }
            //   if(this.tab == 4){
            //     if(el.realisasi_tw4 == '-'){
            //       this.listDataSKPTriwulanSyncWithESakip.push({
            //         "text":el.text,
            //         "id_indikator_esakip":el.id,
            //         "level":el.level
            //       })
            //     }
            //   }
            //   // this.listDataSKPTriwulanSyncWithESakip.push({
            //   //     "text":el.indikator_program,
            //   //     "id":el.idindikator_program
            //   // })
            // });
            store.commit("snackbar/setSnack", {
              message: "Get Data Sinkronisasi Indikator Berhasil!",
              color: "success",
            });
          } else {
            store.commit("snackbar/setSnack", {
              message:
                "Get Data Sinkronisasi Gagal/Data Indikator Kosong. Silahkan periksa kembali di sistem E-Sakip!",
              color: "warning",
            });
          }
        })
        .finally(() => {
          this.loadingBtn.syncSakipProgram = false;
        })
        .catch((err) => {
          var error = err.message ? err.message : err.response.data.message;
          store.commit("snackbar/setSnack", { message: error, color: "error" });
        });
    },
  },

  mounted() {
    this.tab = _g.getTriwulan();
    // 1. Jika nilai pembalik (is_pembalik) nya true, maka perhitungannya: target / realisasi * 100
    // 2. Jika is_pembaliknya false, perhitungannya: realisasi / target * 100
    this.$watch(
      (vm) => [
        vm.formLapor.target,
        vm.formLapor.realisasi,
        vm.formLapor.is_pembalik,
      ],
      (val) => {
        if (this.formLapor.is_pembalik) {
          this.formLapor.capaian = (
            (this.formLapor.target / this.formLapor.realisasi) *
            100
          ).toFixed(2);
        } else {
          this.formLapor.capaian = (
            (this.formLapor.realisasi / this.formLapor.target) *
            100
          ).toFixed(2);
        }
      }
    );
    this.loading = true;
    SKPService.getSKPTriwulan({
      search: this.tableValues.search ? this.tableValues.search : "",
      triwulan: this.tab,
      page: this.tableValues.page,
    }).then((response) => {
      let data = [];
      this.responseData = response.data;
      let responseData = response.data;
      for (let i = 0; i < responseData.data.length; i++) {
        data.push(responseData.data[i]);
        data[i].waktu = data[i].waktu + " " + data[i].satuan_waktu;
      }
      responseData.data = data;
      this.items = responseData;
      this.triwulan_berjalan = parseInt(responseData.triwulan_berjalan);
      this.loading = false;
    });
  },
};
</script>

<style lang="css" scoped>
.label {
  font-size: 12px;
}
</style>
